import {
    ALL_KEY,
    ASSET_CATEGORIES,
    AVS_KEY,
    BITCOIN_AND_OTHERS_KEY,
    DEFAULT_ASSET_CATEGORY,
    DEFAULT_ASSET_TIMEFRAME,
    DEFAULT_ORDER,
    LIQUID_STAKING_KEY,
    ORDER_CHOICES,
    POS_ASSET_CATEGORY,
    STABLECOIN_KEY,
    STABLECOINS_ASSET_CATEGORY,
    TIMEFRAME_CHOICES,
} from 'utils/constants'
import { toCamelCase } from 'utils/formatter'
import {
    ASSETS_SORT_BY_CHOICES,
    AVS_ASSET_METRIC_GROUPS,
    AVS_ASSETS_SORT_BY_CHOICES,
    BITCOIN_AND_OTHERS_METRIC_GROUPS,
    BITCOIN_AND_OTHERS_SORT_BY_CHOICES,
    DEFAULT_ASSETS_SORT_BY,
    DEFAULT_AVS_ASSETS_SORT_BY,
    DEFAULT_LST_ASSETS_SORT_BY,
    DEFAULT_STABLECOINS_SORT_BY,
    getMoreAssetMetricGroups,
    LST_ASSETS_SORT_BY_CHOICES,
    LST_MAIN_ASSET_METRIC_GROUPS,
    MAIN_ASSET_METRIC_GROUPS,
    STABLECOIN_ASSET_METRIC_GROUPS,
    STABLECOINS_ASSET_METRIC_GROUPS,
    STABLECOINS_SORT_BY_CHOICES,
} from '../constants'

export const getMetaInformationForPage = page => {
    const category = ASSET_CATEGORIES.find(category => category.key === page) ?? DEFAULT_ASSET_CATEGORY

    if (page === ALL_KEY) {
        return {
            type: 'Assets',
            description:
                'An overview of all staking assets, including PoS, Liquid Staking and DeFi Staking. Filter by asset type, or ecosystem.',
            title: 'All Staking Assets',
        }
    }

    if (page === POS_ASSET_CATEGORY.key) {
        return {
            type: 'Assets',
            description:
                'An overview of the best Proof of Stake assets. Discover risk, reward, and momentum profiles based on real-time staking on-chain data.',
            title: 'Proof of Stake',
        }
    }

    if (page === LIQUID_STAKING_KEY) {
        return {
            type: 'Assets',
            description: 'An overview of all liquid staking tokens. Sort by key performance metrics.',
            title: 'Liquid Staking Tokens',
        }
    }

    if (page === STABLECOINS_ASSET_CATEGORY.key) {
        return {
            type: 'Assets',
            description:
                'An overview of the best Stablecoins for staking. Discover reward profiles for stablecoin staking and defi lending rates.',
            title: 'Stablecoins',
        }
    }

    if (page === AVS_KEY) {
        return {
            type: 'Assets',
            description:
                'View all Actively Validated Services (AVSs) on EigenLayer, then compare the amount of Restaked ETH, the number of active Operators and the total amount of Restakers for each.',
            title: 'Actively Validated Services',
        }
    }

    if (page === STABLECOIN_KEY) {
        return {
            type: 'Assets',
            description: 'An overview of Stablecoins, including USDT, USDC, DAI & more.',
            title: 'Stablecoins',
        }
    }

    if (page === BITCOIN_AND_OTHERS_KEY) {
        return {
            type: 'Assets',
            description: 'An overview of Bitcoin and other crypto assets.',
            title: 'Bitcoin & Others',
        }
    }

    return {
        type: 'Ecosystem Assets',
        description: `An overview of the best stakeable smart contract tokens on ${
            category?.name ?? 'asset'
        }. Discover fundamental staking data such as real-time protocol cashflows, inflation, and many more.`,
        title: category?.name ?? 'asset',
    }
}

export const getSortedCategoryIcons = (categoryKey = DEFAULT_ASSET_CATEGORY.key, iconsData = null) => {
    const isCategoryWithIcons = ![
        ALL_KEY,
        POS_ASSET_CATEGORY.key,
        LIQUID_STAKING_KEY,
        STABLECOINS_ASSET_CATEGORY.key,
        AVS_KEY,
    ].includes(categoryKey)

    const categoryNativeAssetIcon =
        iconsData?.[`${toCamelCase(categoryKey)}NativeAsset`]?.map(a => a?.logoUrl)?.[0] ?? null

    const categoryIcons = isCategoryWithIcons
        ? (iconsData?.[toCamelCase(categoryKey)]
              ?.map(a => a?.logoUrl)
              ?.filter(url => url !== categoryNativeAssetIcon) ?? [])
        : []

    const iconsToShow = categoryNativeAssetIcon
        ? [...categoryIcons.slice(0, 4), categoryNativeAssetIcon]
        : [...categoryIcons.slice(0, 5)]

    return iconsToShow
}

export const getNetStakingFlowItem = (metricsItems = [], item = null) => metricsItems?.find(a => a?.id === item?.id)

export const getAssetMetricGroupsPerCategory = (categoryKey = DEFAULT_ASSET_CATEGORY.key) => {
    if (categoryKey === STABLECOINS_ASSET_CATEGORY.key) {
        return STABLECOINS_ASSET_METRIC_GROUPS
    }

    if (categoryKey === LIQUID_STAKING_KEY) {
        return LST_MAIN_ASSET_METRIC_GROUPS
    }

    if (categoryKey === AVS_KEY) {
        return AVS_ASSET_METRIC_GROUPS
    }

    if (categoryKey === STABLECOIN_KEY) {
        return STABLECOIN_ASSET_METRIC_GROUPS
    }

    if (categoryKey === BITCOIN_AND_OTHERS_KEY) {
        return BITCOIN_AND_OTHERS_METRIC_GROUPS
    }

    return MAIN_ASSET_METRIC_GROUPS
}

export const getAssetAllMetricGroups = (categoryKey = DEFAULT_ASSET_CATEGORY.key, slug = '') => {
    return [...getAssetMetricGroupsPerCategory(categoryKey), ...getMoreAssetMetricGroups(slug)]
}

export const getAssetSortByOptionsPerCategory = (categoryKey = DEFAULT_ASSET_CATEGORY.key) => {
    if (categoryKey === STABLECOINS_ASSET_CATEGORY.key) {
        return STABLECOINS_SORT_BY_CHOICES
    }

    if (categoryKey === LIQUID_STAKING_KEY) {
        return LST_ASSETS_SORT_BY_CHOICES
    }

    if (categoryKey === AVS_KEY) {
        return AVS_ASSETS_SORT_BY_CHOICES
    }

    if (categoryKey === STABLECOIN_KEY) {
        return STABLECOINS_SORT_BY_CHOICES
    }

    if (categoryKey === BITCOIN_AND_OTHERS_KEY) {
        return BITCOIN_AND_OTHERS_SORT_BY_CHOICES
    }

    return ASSETS_SORT_BY_CHOICES
}

export const getDefaultAssetSortByOptionPerCategory = (categoryKey = DEFAULT_ASSET_CATEGORY.key) => {
    if (categoryKey === STABLECOINS_ASSET_CATEGORY.key) {
        return DEFAULT_STABLECOINS_SORT_BY
    }

    if (categoryKey === LIQUID_STAKING_KEY) {
        return DEFAULT_LST_ASSETS_SORT_BY
    }

    if (categoryKey === AVS_KEY) {
        return DEFAULT_AVS_ASSETS_SORT_BY
    }

    if (categoryKey === STABLECOIN_KEY || categoryKey === BITCOIN_AND_OTHERS_KEY) {
        return DEFAULT_STABLECOINS_SORT_BY
    }

    return DEFAULT_ASSETS_SORT_BY
}

export const getDefaultAssetColumnsPerCategory = (categoryKey = DEFAULT_ASSET_CATEGORY.key) => {
    const metricGroup = getAssetMetricGroupsPerCategory(categoryKey)

    return metricGroup.map(({ key }) => key)
}

export const getParamsFromUrl = (query = {}, queryKey = '') => {
    if (queryKey === 'sort') {
        return (
            getAssetSortByOptionsPerCategory(query?.page)?.find(so => so?.key === query?.sort) ??
            getDefaultAssetSortByOptionPerCategory(query?.page)
        )
    }

    if (queryKey === 'timeframe') {
        return TIMEFRAME_CHOICES?.find(ti => ti?.key === query?.timeframe) ?? DEFAULT_ASSET_TIMEFRAME
    }

    if (queryKey === 'order') {
        return ORDER_CHOICES?.find(or => or?.key === query?.order) ?? DEFAULT_ORDER
    }

    if (queryKey === 'byChange') {
        return String(query?.byChange) === 'true'
    }

    if (queryKey === 'search') {
        return query?.search ?? ''
    }

    if (queryKey === 'columns') {
        return query?.columns?.split(',') ?? getDefaultAssetColumnsPerCategory(query?.page)
    }

    return ASSET_CATEGORIES?.find(c => c?.key === query?.page) ?? DEFAULT_ASSET_CATEGORY
}

export const getParamsFromQuery = query => {
    return {
        category: getParamsFromUrl(query, 'page'),
        sortBy: getParamsFromUrl(query, 'sort'),
        timeframe: getParamsFromUrl(query, 'timeframe'),
        order: getParamsFromUrl(query, 'order'),
        byChange: getParamsFromUrl(query, 'byChange'),
        search: getParamsFromUrl(query, 'search'),
        columns: getParamsFromUrl(query, 'columns'),
    }
}

export const reorderArrayBySlug = (array, order) => {
    const orderMap = new Map(order.map((slug, index) => [slug, index]))
    return array?.sort((a, b) => (orderMap.get(a.slug) ?? Infinity) - (orderMap.get(b.slug) ?? Infinity))
}
