import classNames from 'classnames'
import { Link } from './link'

export const ExternalLinkVariant = {
    Primary: 'primary',
    Fg: 'fg',
}

export const ExternalLink = ({
    href = '',
    variant = ExternalLinkVariant.Fg,
    label = '',
    large = false,
    className = '',
    icon = '',
}) => {
    return (
        <Link
            className={classNames(
                'flex flex-row items-center gap-1.5 border-none p-1.5 opacity-75 transition-opacity duration-200 ease hover:opacity-100',
                {
                    'hover:underline text-primary':
                        variant === ExternalLinkVariant.Primary,
                    'gap-4 text-lg font-bold': large,
                },
                className
            )}
            href={href}
            blank
        >
            <span
                className={classNames(
                    `icon ${icon}`,
                    'text-[20px] relative -top-[2px]'
                )}
            />
            <span
                className={classNames('text-contrast-5 text-sm font-normal', {
                    'text-primary': variant === ExternalLinkVariant.Primary,
                    'text-lg font-bold': large,
                })}
            >
                {label}
            </span>
            <span
                className={classNames(
                    'icon icon-external relative text-[10px] -top-[2px]',
                    {
                        'text-[14px] -top-[1px]': large,
                    }
                )}
            />
        </Link>
    )
}
