import Image from 'next/image'
import { Link } from './link'

export const YoutubeLink = ({ href = '#', label = 'Link' }) => {
    return (
        <Link
            className='flex flex-row gap-3 items-center text-sm text-contrast-3 leading-none hover:underline'
            href={href}
            blank
        >
            <span>{label}</span>
            <Image
                src={`/static/img/youtube-play-button.png`}
                width={20}
                height={20}
                alt={label}
                placeholder='empty'
                priority
            />
        </Link>
    )
}
