import classNames from 'classnames';

export const ResetButton = ({ className = '', show = false, onClick = () => {} }) => {
    return (
        <button
            className={classNames(
                'border-none p-0 block bg-transparent z-0 h-5',
                { hidden: !show },
                className
            )}
            onClick={onClick}
        >
            <span className="icon icon-og icon-reset" />
        </button>
    );
};