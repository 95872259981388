import classNames from 'classnames'
import { useClickOutside } from 'hooks'
import { useRef, useState } from 'react'
import { NO_DATA_INDICATOR } from 'utils/formatter'

import styles from './dropdownSelect.module.scss'

export function DropdownSelect({
    className = '',
    choices = [],
    sections = [],
    selected = null,
    onSelect = () => {},
    renderItem = () => {},
    renderSelected = () => {},
    small = false,
}) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    useClickOutside(dropdownRef, () => setIsOpen(false))

    const renderChoicesList = choices => {
        return (
            <ul>
                {choices.map((choice, idx) => (
                    <li
                        key={`choice-${choice?.key}-${idx}`}
                        className={classNames({
                            [styles.selectedOption]: choice?.key === selected?.key,
                        })}
                        onClick={() => {
                            onSelect(choice)
                            setIsOpen(false)
                        }}
                    >
                        {renderItem?.(choice) ?? choice?.name ?? NO_DATA_INDICATOR}
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <div
            className={classNames(styles.dropdown, className, 'bg-white dark:bg-contrast-1', {
                [styles.small]: small,
            })}
            ref={dropdownRef}
        >
            <div
                className={classNames(styles.selected, {
                    [styles.hidden]: isOpen,
                })}
                onClick={() => {
                    if ((choices?.length ?? 0) > 0) {
                        setIsOpen(!isOpen)
                    }
                }}
            >
                <div className='flex flex-row gap-4 w-full justify-between items-center'>
                    <div className={styles.name}>
                        {renderSelected?.(selected) ?? selected?.name ?? NO_DATA_INDICATOR}
                    </div>
                    {(choices?.length ?? 0) > 0 && (
                        <span className={classNames(`icon icon-og icon-chevron-arrow-down`, styles.arrow)} />
                    )}
                </div>
            </div>
            <div
                className={classNames(styles.options, 'bg-white dark:bg-contrast-1', {
                    [styles.hidden]: !isOpen,
                })}
            >
                <div className='flex flex-col w-full'>
                    <div
                        key={`choice-${selected?.key}-selected`}
                        className={`flex flex-row justify-between gap-4 items-center w-full whitespace-nowrap truncate cursor-pointer px-3 py-1 font-normal ${styles.selectedOpen}`}
                        onClick={() => {
                            onSelect(selected)
                            setIsOpen(false)
                        }}
                    >
                        <div className={styles.name}>
                            {renderSelected?.(selected) ?? selected?.name ?? NO_DATA_INDICATOR}
                        </div>
                        <span
                            className={classNames(`icon icon-og icon-chevron-arrow-up`, styles.arrow, styles.arrowUp)}
                        />
                    </div>
                    {sections.length ? (
                        <div className='flex flex-col gap-2'>
                            {sections.map((section, index) => {
                                const sectionChoices = choices.filter(choice => choice?.section === section?.key)

                                return (
                                    <div
                                        key={`section-${section?.key}`}
                                        className={classNames(
                                            'flex flex-col gap-2 pt-3',
                                            index !== 0 ? 'border-t border-solid border-contrast-1' : ''
                                        )}
                                    >
                                        <span className='text-xs text-contrast-2 px-5'>{section?.name}</span>
                                        {renderChoicesList(sectionChoices)}
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        renderChoicesList(choices)
                    )}
                </div>
            </div>
        </div>
    )
}
