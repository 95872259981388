import classNames from 'classnames'
import { memo } from 'react'

export const ToggleButtonComponent = ({
    expanded = false,
    hidden = false,
    onClick = () => {},
    className,
}) => {
    return (
        <div
            className={classNames(
                'cursor-pointer p-0 border-none text-left flex items-center w-6 h-6 bg-transparent opacity-100',
                {
                    'opacity-0 cursor-default': hidden,
                },
                className
            )}
            onClick={() => {
                if (!hidden) {
                    onClick()
                }
            }}
        >
            <div
                className={classNames(
                    'relative w-6 h-6 transform transition-transform duration-400 ease-[cubic-bezier(0.68,-0.55,0.265,1.55)] origin-center rotate-[-45deg]',
                    {
                        'rotate-[90deg]': expanded,
                    }
                )}
            >
                <span className='absolute w-[12px] h-[3px] bg-primary top-1/2 left-1/2 rounded transform -translate-x-1/2 -translate-y-1/2 rotate-[-45deg] transition-colors duration-400 ease'></span>
                <span className='absolute w-[12px] h-[3px] bg-primary top-1/2 left-1/2 rounded transform -translate-x-1/2 -translate-y-1/2 rotate-[45deg] transition-colors duration-400 ease'></span>
            </div>
        </div>
    )
}

export const ToggleButton = memo(ToggleButtonComponent)
