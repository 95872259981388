import { classed } from '@tw-classed/react'
import classNames from 'classnames'
import { Skeleton } from 'components/ui/skeleton'
import { forwardRef } from 'react'

const ButtonComponent = classed.button(
    'font-semibold duration-300 border border-transparent leading-none', // default shared style
    'disabled:bg-black/5 disabled:text-black/50 disabled:border-transparent disabled:border-none disabled:cursor-not-allowed dark:disabled:bg-white/5 dark:disabled:text-white', // disabled shared style
    {
        variants: {
            variant: {
                black: 'text-white bg-black/90 hover:bg-black dark:bg-white/80 dark:text-black',
                blue: 'text-white bg-blue hover:bg-blue/80',
                gray: 'text-black/50 bg-black/5 hover:bg-black/15 dark:bg-white/10 dark:text-white',
                'blue-light': 'text-blue bg-blue/10 hover:bg-blue/20',
                dashed: 'text-black/80 bg-transparent border-dashed !border-black/90 hover:text-black/90 hover:border-solid',
                white: 'text-black/80 bg-white border-solid !border-black/80 hover:text-black/90 hover:border-black/40',
                transparent: 'text-black/80 bg-transparent border-transparent hover:text-black/90',
            },
            size: {
                sm: 'text-sm min-h-[34px] px-3 leading-none py-0',
                md: 'text-sm min-h-[42px] px-4 leading-none py-0',
            },
            rounded: {
                true: 'rounded-lg',
            },
            full: {
                true: 'w-full',
            },
        },
        defaultVariants: {
            size: 'sm',
            variant: 'blue',
            rounded: true,
            full: false,
        },
    }
)

const ButtonIcon = classed.div('icon size-[18px]', {
    variants: {
        variant: {
            black: 'text-white bg-black/90 hover:bg-black dark:bg-white/80 dark:text-black',
            'blue-light': '!bg-blue',
            blue: '!bg-white',
            gray: '!bg-black/50 dark:!bg-white',
            transparent: '!bg-black/80',
            disabled: '!bg-black/50',
        },
    },

    defaultVariants: {
        variant: 'blue',
    },
})

const DEFAULT_ICON_POSITION = 'right'

export const Button = forwardRef((props, ref) => {
    const {
        iconClass = '',
        iconStyle = {},
        children,
        variant = 'blue',
        disabled = false,
        isLoading = false,
        size = 'sm',
        noMinimumHeight = false,
        iconPosition = DEFAULT_ICON_POSITION,
        ...rest
    } = props
    return (
        <ButtonComponent data-variant={variant} variant={variant} disabled={disabled} size={size} {...rest} ref={ref}>
            <div
                className={classNames(
                    'flex items-center justify-center gap-2',
                    iconPosition === 'right' && 'flex-row-reverse',
                    size === 'sm' ? 'min-h-[34px]' : 'min-h-[42px]',
                    noMinimumHeight === true && '!min-h-0'
                )}
            >
                {iconClass && (
                    <ButtonIcon
                        variant={disabled ? 'disabled' : variant}
                        disabled={disabled}
                        className={iconClass}
                        style={{
                            ...iconStyle,
                        }}
                    />
                )}
                {isLoading ? (
                    <Skeleton width={'80px'} height={'24px'} borderRadius={4} />
                ) : (
                    <>
                        {children && (
                            <span
                                className={classNames('text-sm leading-none', {
                                    'text-shade-lighter': disabled,
                                })}
                            >
                                {children}
                            </span>
                        )}
                    </>
                )}
            </div>
        </ButtonComponent>
    )
})

Button.displayName = 'Button'
