import { useQuery } from '@tanstack/react-query'
import { Link } from 'components/ui/links/link'
import { useFloatingBanner } from 'contexts/floatingBannerContext'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { uiState } from 'state/ui'
import { GTMEvent, logEvent } from 'utils/GTM'

export const FloatingBanner = () => {
    const router = useRouter()
    const [isVisible, setIsVisible] = useState(true)
    const { floatingBannerState } = useFloatingBanner()
    const isPageBottom = uiState.isPageBottom.value

    const {
        data: image,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['bannerImage', floatingBannerState],
        queryFn: async () => {
            const api = `${process.env.NEXT_PUBLIC_AD_MANAGER_URL}/api/b?asset=${floatingBannerState.asset}&tag=${floatingBannerState.ecosystem || floatingBannerState.tag}`
            const response = await fetch(api, {
                headers: { 'X-API-VERSION': 1 },
            })

            const result = await response.json()
            const { name, destination_url, id } = result || {}
            const url = `${process.env.NEXT_PUBLIC_AD_MANAGER_URL}${result.location_url || ''}`
            const smallImageUrl = `${process.env.NEXT_PUBLIC_AD_MANAGER_URL}${result?.small_location_url}`

            return {
                imageUrl: url,
                smallImageUrl,
                id,
                name,
                destination: destination_url,
                alt: 'Ad Banner',
            }
        },
        enabled: Boolean(floatingBannerState),
    })

    if (router.asPath.includes('/terminal') || router.asPath.includes('/summit') || router.asPath.includes('/sreth')) {
        return null
    }

    if (!image?.id) return null

    return (
        !isLoading &&
        !isError &&
        image?.id && (
            <div
                id='sticky-footer'
                className='relative bottom-0 z-[80] flex justify-center items-center w-full'
            >
                <div
                    className={`fixed bottom-0 flex justify-center items-center px-5 pb-0 mb-[-50px] bg-shade-lightest-color shadow-lg transition-all duration-200 ${
                        !isVisible
                            ? 'hidden opacity-0 translate-y-0'
                            : 'opacity-100 translate-y-[-64px]'
                    } ${isPageBottom ? 'mb-0' : ''}`}
                >
                    <Link
                        className='flex items-center justify-center'
                        href={image.destination}
                        onClick={() =>
                            logEvent(GTMEvent.AdLeaderboardBanner, {
                                ad_action: 'click',
                                ad_id: image.id,
                                ad_name: image.name,
                                click_url: image.destination,
                            })
                        }
                    >
                        <Image
                            className='block max-w-[320px] justify-center items-center h-[50px] w-[400px] sm:hidden self-center'
                            alt={image.alt}
                            src={image.smallImageUrl}
                            width={0}
                            height={0}
                            sizes='(max-width: 500px) 100vw'
                        />
                        <Image
                            className='items-center self-center justify-center hidden sm:block'
                            alt={image.alt}
                            src={image.imageUrl}
                            width={0}
                            height={0}
                            sizes='(max-width: 728px) 728px, (max-width: 768px) 728px, (max-width: 1024px) 970px, 1140px'
                            style={{
                                width: '100%',
                                maxWidth: '1140px',
                                height: 'auto',
                            }}
                        />
                        <div
                            className='absolute z-[1000] top-[-5px] right-[-6px] p-1 border border-contrast-2 rounded-full bg-white flex cursor-pointer'
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsVisible(false)
                            }}
                        >
                            <span className='icon icon-close text-contrast-2'></span>
                        </div>
                    </Link>
                </div>
            </div>
        )
    )
}
