import { useTrendingAssets } from 'data/queries'
import Image from 'next/image'
import { TYPE_ASSET } from 'utils/constants'
import { WithSearch } from './search'
import { StackedItems } from './stackedItems'


export const TrendingAssetsView = ({ isFooter = false }) => {
    const { data, isValidating: isLoading } = useTrendingAssets(null, 10, 0)

    return (
        <WithSearch focusOnMount={!isFooter}>
            <div className="flex flex-col gap-5 w-full px-5 py-[12px]">
                <p className="text-base text-contrast-4 font-bold flex items-center">
                    Trending{' '}
                    <span className="w-4 h-4 ml-2">
                        <Image
                            src='/static/svg/flame.svg'
                            alt='Trending'
                            fill
                            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                        />
                    </span>
                </p>
                <StackedItems data={data?.assets} isLoading={isLoading} type={TYPE_ASSET} />
            </div>
        </WithSearch>
    )
}
