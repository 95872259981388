import classNames from 'classnames'
import { Link } from 'components/ui'

export function Tab({ id, title, link, isActive = false, onClick = () => {} }) {
    const handleClick = () => {
        onClick(id)
    }

    const content = (
        <div
            className={classNames(
                'flex justify-center items-center px-1 py-2 cursor-pointer text-contrast-3 md:py-5',
                'border-b-2 border-solid border-transparent',
                'transition-colors',
                'hover:bg-primary-10 hover:text-contrast-4',
                isActive && '!text-primary !border-primary'
            )}
        >
            <span className={'font-medium text-center md:whitespace-nowrap'}>{title}</span>
        </div>
    )

    if (link) {
        return (
            <Link className='w-full' href={link} onClick={handleClick}>
                {content}
            </Link>
        )
    }

    return (
        <div className='w-full cursor-pointer' onClick={handleClick}>
            {content}
        </div>
    )
}
