import { useQuery } from '@tanstack/react-query'
import { fetchAssetTags } from 'data/queries'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
    BITCOIN_AND_OTHERS_KEY,
    BNB_ECOSYSTEM_KEY,
    COSMOS_ECOSYSTEM_KEY,
    ETHEREUM_ECOSYSTEM_KEY,
    LIQUID_RESTAKING_KEY,
    LIQUID_STAKING_KEY,
    NATIVE_RESTAKING_KEY,
    POLKADOT_ECOSYSTEM_KEY,
    POS_ASSET_CATEGORY,
    STABLECOIN_KEY,
    STABLECOINS_ASSET_CATEGORY,
} from 'utils/constants'
import { findByEcosystem } from 'utils/findByEcosystem'
import { findByTag } from 'utils/findByTag'
import { getGoogleTag } from './utils'

const checkForAdblocker = () => {
    return new Promise(resolve => {
        if (navigator?.doNotTrack === '1') {
            resolve(true) // AdBlocker detected due to "Do Not Track"
            return
        }

        const script = document.createElement('script')
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

        const onScriptLoad = () => {
            document.body.removeChild(script) // Clean up the script
            resolve(false) // AdBlocker not detected
        }

        const onScriptError = () => {
            document.body.removeChild(script) // Clean up the script
            resolve(true) // AdBlocker detected
        }

        script.onload = onScriptLoad
        script.onerror = onScriptError

        document.body.appendChild(script)
    })
}

/**
 * Initialize this on _app.js only once, then access it from getGoogleTag()
 */
export const useGoogleTag = () => {
    const [googleTag, setGoogleTag] = useState(null)

    useEffect(() => {
        checkForAdblocker().then(hasAdBlocker => {
            if (!hasAdBlocker && !googleTag) {
                const script = document.createElement('script')
                script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
                script.onload = () => {
                    const newTag = getGoogleTag()
                    setGoogleTag(newTag)
                    newTag.cmd.push(function () {
                        newTag.enableServices()
                    })
                }
                script.onerror = () => {
                    setGoogleTag(null)
                }
                document.body.appendChild(script)
                return () => {
                    document.body.removeChild(script)
                }
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return { googleTag }
}

const MAX_RETRIES = 50 // 5 seconds

// Recursively wait for the GAM API to be ready
function waitForGAMAPIReady(maxRetries = MAX_RETRIES) {
    let attempts = 0
    return new Promise((resolve, reject) => {
        function checkAPIReady() {
            const googletag = getGoogleTag()
            if (googletag?.apiReady) {
                resolve()
            } else if (attempts < maxRetries) {
                attempts++
                setTimeout(checkAPIReady, 100)
            } else {
                reject(new Error('GAM API not ready after max attempts'))
            }
        }
        checkAPIReady()
    })
}

export const useSharedImageAdState = () => {
    const router = useRouter()
    const { slug, page } = router.query

    const [imageAdState, setImageAdState] = useState({
        asset: null,
        tag: null,
        ecosystem: null,
    })

    const { data: assetTagsResponse } = useQuery({
        queryKey: ['assetTags', slug],
        queryFn: () => fetchAssetTags(slug),
        enabled: Boolean(
            slug &&
                router.asPath.startsWith('/asset/') &&
                !router.asPath.startsWith('/assets')
        ),
    })

    useEffect(() => {
        if (router.asPath.startsWith('/asset/') && !router.asPath.startsWith('/assets')) {
            if (assetTagsResponse) {
                const tagKeys = assetTagsResponse?.assets?.[0]?.tags?.map(tag => tag.tagKey) || []
                const tagKeyEcosystem = findByEcosystem(tagKeys)
                const tagKeyTag = findByTag(tagKeys)

                setImageAdState({
                    asset: slug,
                    tag: tagKeyTag || null,
                    ecosystem: tagKeyEcosystem || null,
                })
            }
        } else if (router.asPath.startsWith('/assets')) {
            const isTag =
                page === LIQUID_STAKING_KEY ||
                page === STABLECOIN_KEY ||
                page === BITCOIN_AND_OTHERS_KEY ||
                page === LIQUID_RESTAKING_KEY ||
                page === NATIVE_RESTAKING_KEY ||
                page === POS_ASSET_CATEGORY.key ||
                page === STABLECOINS_ASSET_CATEGORY.key

            const isEcosystem =
                page === ETHEREUM_ECOSYSTEM_KEY ||
                page === COSMOS_ECOSYSTEM_KEY ||
                page === POLKADOT_ECOSYSTEM_KEY ||
                page === BNB_ECOSYSTEM_KEY

            setImageAdState({
                asset: null,
                tag: isTag ? page : null,
                ecosystem: isEcosystem ? page : null,
            })
        } else {
            setImageAdState({
                asset: null,
                tag: null,
                ecosystem: null,
            })
        }
    }, [router.asPath, page, slug, assetTagsResponse])

    return { imageAdState }
}
