import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { ShareTooltip } from '../shareTooltip'

export const ShareButton = ({
    href = '',
    title = '',
    hashtags = [],
    metricsText = '',
}) => {
    const [showShare, setShowShare] = useState(false)

    const onTooltipClose = useCallback(() => {
        setShowShare(false)
    }, [])

    return (
        <ShareTooltip
            pageLink={href}
            name={title}
            hashtags={hashtags}
            metricsText={metricsText}
            forceShow={showShare}
            onClose={onTooltipClose}
        >
            <button
                className='flex flex-row gap-1.5 items-center border-none p-1.5 text-sm transition-colors duration-400 ease-in-out bg-transparent text-contrast-3 hover:text-contrast-4'
                onClick={() => {
                    setShowShare(true)
                }}
            >
                <span>Share</span>
                <span
                    className={classNames(
                        'icon icon-share relative -top-[2px] text-contrast-3 transition-opacity',
                        { hidden: !showShare }
                    )}
                />
            </button>
        </ShareTooltip>
    )
}
