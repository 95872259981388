import classNames from 'classnames'
import { useClickWithTimeout } from 'hooks'

const initialCopyingLabel = 'Copy Link'

export const CopyLinkButton = ({ className = '', href = '', copyLinkText = initialCopyingLabel }) => {
    const { copyingMessage, handleClickCopy } = useClickWithTimeout(href, copyLinkText)
    return (
        <button
            className={classNames(
                'flex flex-row gap-1 justify-center items-center border-none p-1.5 text-sm transition-colors ease-in-out duration-400 bg-transparent text-contrast-3 text-right hover:text-contrast-4',
                className
            )}
            onClick={handleClickCopy}
        >
            <span>{copyingMessage}</span>
            <span
                className={classNames(
                    'icon icon-copy-link relative -top-[2px] text-contrast-3',
                    {
                        hidden: copyingMessage !== copyLinkText,
                        'hover:text-contrast-4':
                            copyingMessage === copyLinkText,
                    }
                )}
            />
        </button>
    )
}
