import { FloatingBanner } from 'components/ads/floatingBanner'
import { Footer } from 'components/footer'
import { useRouterPages } from 'components/navbar/hooks'
import dynamic from 'next/dynamic'
import { useMediaPredicate } from 'react-media-hook'
import { useContainerScrollToTop } from './useContainerScrollToTop'

const PageControl = dynamic(() => import('components/pageControl/').then(module => module.PageControl), {
    ssr: false,
})

export function ContentContainer({ children }) {
    const isTablet = useMediaPredicate('(min-width: 768px)')

    const containerRef = useContainerScrollToTop()

    const { isStakeAppPage, isSwapAppPage } = useRouterPages()

    return (
        <div className='w-full h-screen flex flex-col overflow-x-hidden overflow-y-auto' ref={containerRef}>
            <div className='flex-grow'>
                {children}
                {!isStakeAppPage && !isSwapAppPage && <FloatingBanner />}
                <PageControl />
            </div>
            <div className='w-full'>
                <Footer />
            </div>
        </div>
    )
}
